// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name=' + this.hash.slice(1) +']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'quart');
        return false;
      }
    }
  });

});

// slide btn

$(function(){
  $(".js-slideBtn").each(function(index,element){
      $(element).click(function(){
        $(this).toggleClass('is-active');
        $(element).next(".js-slidePanel").slideToggle(300);
        });
      });

  // ハッシュがあるときはオープン
  if(window.location.hash.length>0){
    $(window.location.hash).toggleClass('on').next(".js-slidePanel").toggle();
  }
});


// current

$(function(){
  var gNavItem = $("#gnav .js-gnavItem");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('is-current');
    } else {
      $(this).removeClass('is-current');
    }
  });

});


// header scroll settings

$(window).on('load scroll resize', function(){
  var winW = $(window).width();
  var winscrW = window.innerWidth;  /* スクロールバーを含む幅 */
  var contentTop = $('#hero');
  var gnav = $('#gnavfix');
  var logo = $('#js-hdlogo');
  var contactBtns = $('#ganvContact');
  var scroll = $(window).scrollTop();

  if( scroll < 150 && winscrW > 799 ) {
    gnav.addClass('is-head');
    contentTop.css({'padding-top':'0'});
    logo.hide();
    contactBtns.removeClass('is-scroll');
  } else if ( winscrW > 799 ) {
    gnav.removeClass('is-head');
    contentTop.css({'padding-top':'66px'});
    logo.show();
    contactBtns.addClass('is-scroll');
  } else {
    contentTop.css({'padding-top':'60px'});
    logo.show();
    contactBtns.removeClass('is-scroll');
  }
});

// footer padding
$(window).on('load scroll resize', function(){
  var footer = $('footer');
  var fixnavH = $('#fixBtmNav').outerHeight(true);
  footer.css({'padding-bottom' : fixnavH });
});


//fadein

$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});